import request from '@/utils/request';

// 获取个人信息
export const getUserInfoApi = () => request('/user/user_info');

// 发送邮箱验证码
export const sendEmailCodeApi = (in_mail) =>
  request({
    url: '/tool/email_send',
    method: 'post',
    data: { in_mail }
  });

// 修改邮箱
export const changeEmailApi = (data) =>
  request({
    url: '/user/mail_edit',
    method: 'post',
    data
  });

// 发送手机验证码
export const sendMobileCodeApi = (in_mobile) =>
  request({
    url: '/tool/phone_send',
    method: 'post',
    data: { in_mobile }
  });

// 修改手机号
export const changeMobileNumberApi = (data) =>
  request({
    url: '/user/mobile_edit',
    method: 'post',
    data
  });

// 图片上传
export const uploadImageApi = (formData) =>
  request({
    url: '/tool/file_up',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  });

// 换绑微信
export const changeWeiXinApi = (data) =>
  request({
    url: '/user/wx_bind',
    method: 'post',
    data
  });

// 个人认证
export const personalAuthApi = (data) =>
  request({
    url: '/user/person_auth',
    method: 'post',
    data
  });

// 企业认证
export const companyAuthApi = (data) =>
  request({
    url: '/user/company_auth',
    method: 'post',
    data
  });

// 创建订单
export const order_create = (data) =>
  request({
    url: '/pay/order_create',
    method: 'post',
    data
  });
// 查询支付状态
export const order_result = (params) =>
  request({
    url: '/pay/order_result',
    params
  });
