import axios from 'axios';
import store from '@/store';
import { Message } from 'element-ui';

const request = axios.create({
  baseURL: '/api',
  timeout: 5000
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    const token = store.state.token;
    // 在发送请求之前做些什么
    if (token) {
      config.headers.token = token;
      // config.headers.token =
      //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhZG1pbi5vbGF5dW4uY29tIiwiYXVkIjoiYWRtaW4ub2xheXVuLmNvbSIsImV4cCI6MTczMTkzODAwMywiaWF0IjoxNzAwNDAyMDAzLCJuYmYiOjE3MDA0MDIwMDMsInNjb3BlcyI6InJvbGVfYWNjZXNzIiwiZGF0YSI6eyJpbl91c2VyaWQiOjU0MCwiaW5fdXNlcm5hbWUiOiI3Nzg4IiwiaW5fdXNlcnBhc3N3b3JkIjoiZjFjZjExMjYzMTU1NWVmOCIsImluX21haWwiOiI1NDA0NjQ0NTRAcXEuY29tIiwiaW5fdW5pb25pZCI6Im96MUtoNnF0bGlDdEowODNBUEs1RkpXV3NvM0UiLCJpbl9tb2JpbGUiOiIxODc5MDkwMTg1NiIsImluX3N2aXAiOiJcdTRmNTNcdTlhOGNcdTRmMWFcdTU0NTgiLCJpbl92aXB0aW1lIjoxNzI3NTI0OTcyLCJpbl9uaWNrIjoiXHU3MzhiXHU2MGUwXHU2NTg3IiwiaW5fY2FyZCI6IjQxMTEyMzE5OTAxMTA3MDAxMSIsImluX25hbWVxeSI6Ilx1NmIyN1x1NTU2Nlx1NGU5MSIsImluX2NhcmRxeSI6InNhc2Fzc3h6eCAiLCJpbl9pbWdxeSI6Imh0dHBzOi8vZG93bi5rZWJlaS52aXAvenJmdnJhZDFkampoN2QycmQyNDN2czI3ZnZ5d3ZwZWcuanBnIiwiaW5faW1nem0iOiJodHRwczovL2Rvd24ua2ViZWkudmlwL3pyZnZyYWQxZGpqaDdkMnJkMjQzdnMyN2Z2eXd2cGVnLmpwZyIsImluX2ltZ2ZtIjoiaHR0cHM6Ly9kb3duLmtlYmVpLnZpcC96cmZ2cmFkMWRqamg3ZDJyZDI0M3ZzMjdmdnl3dnBlZy5qcGciLCJpbl9pbWdzYyI6Imh0dHBzOi8vZG93bi5rZWJlaS52aXAvenJmdnJhZDFkampoN2QycmQyNDN2czI3ZnZ5d3ZwZWcuanBnIiwiaW5fcmVnZGF0ZSI6IjIwMjMtMDQtMTEgMTk6NDI6MzYiLCJpbl9sb2dpbmlwIjoiMS43MS4xNDYuMTYyIiwiaW5fbG9naW50aW1lIjoiMjAyMy0xMC0zMCAxODoyNToxOSIsImluX3R5cGUiOjIsImluX3ZlcmlmeSI6MiwiaW5faW5mbyI6Ilx1OGJjMVx1NGVmNlx1NGUwZFx1ODljNFx1ODMwM1x1ZmYwY1x1OGJmN1x1NjMwOVx1NzkzYVx1NGY4Ylx1NmI2M1x1Nzg2ZVx1NGUwYVx1NGYyMCIsImluX2lzbG9jayI6MCwiaW5fcmVsZWFzZSI6MCwiaW5fcG9pbnRzIjoyMjExMCwiaW5fZmlsZXNpemUiOiIzMDAgTSIsImluX3NwYWNldXNlIjoiMjAuOCBNQiIsImluX3NwYWNldG90YWwiOiIxIEdCIiwiaW5fdXNlX2F1dGhfY29kZSI6MCwiZGV2aWNlX251bSI6MCwiZGV2aWNlX3VzZWQiOjAsInBydl9kZXZpY2VfbnVtIjowLCJwcnZfZGV2aWNlX3VzZWQiOjAsIm1heF9zdXBlcl9jb2RlIjowLCJtb25leSI6IjIxMDIuMDAiLCJwaWQiOjAsInBtYXJrIjoiIiwicHJvbW90ZV9jb2RlIjoiOWF5eHRkYnciLCJwcm9tb3RlX21vbmV5IjoiMC4wMCIsInByb21vdGVfbW9uZXlfY2FzaCI6IjAuMDAiLCJjb3N0X21vbmV5IjoiMzI0OC4wMCIsImZvcnBfbW9uZXkiOiIwLjAwIiwidHNldHN0IjowLCJ1bmZvcnBfbW9uZXkiOiI2NDkuNjAifX0.9YD12Nl6CPQUF8Bqa41QQYEbpZbX1Y0u0GlCyIyit9U';
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response) {
      return response.data;
    }
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default request;
