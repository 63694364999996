import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { loginApi } from '@/api/index';
import { getUserInfoApi } from '@/api/person';
import { _getConfig } from '@/api/App';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    user: {},
    time: 0,
    Config: {}
  },
  mutations: {
    setUser(state, data) {
      state.user = data.user;
      state.token = data.token;
      state.time = +new Date();
    },
    removeUser(state) {
      state.user = {};
      state.token = '';
    },
    resetUser(state, data) {
      state.user = data;
    },
    setConfig(state, data) {
      state.Config = data;
    }
  },
  actions: {
    async login({ commit }, data) {
      const res = await loginApi(data);
      if (res.status === 0) {
        commit('setUser', res.data);
      }
      return res;
    },
    async setConfig({ commit }) {
      const config = await _getConfig();
      commit('setConfig', config.data);
    },
    async ReSetUser({ commit, state }) {
      if (state.token) {
        const res = await getUserInfoApi();
        commit('resetUser', res.data);
      }
    }
  },
  plugins: [
    createPersistedState({
      key: 'OLAYUN'
    })
  ]
});
