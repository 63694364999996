import request from '@/utils/request';

//应用列表
export function getAppList(params) {
  return request({
    url: '/app/app_list',
    params
  });
}

//应用详情
export function getAppDetails(id) {
  return request({
    url: '/app/app_info',
    params: { in_id: id }
  });
}

//删除应用
export function delApp(id) {
  return request({
    url: '/app/app_del',
    method: 'post',
    data: { in_id: id }
  });
}

//应用设置
export function appEdit(data) {
  return request({
    url: '/app/app_edit',
    method: 'post',
    data
  });
}

//应用合并
export function appMerge(data) {
  return request({
    url: '/app/app_merge',
    method: 'post',
    data
  });
}

//合并解绑
export function mergeCancel(id) {
  return request({
    url: '/app/app_merge_cancel',
    method: 'post',
    data: { in_id: id }
  });
}

export function appDown(link) {
  return request({
    url: '/app/app_down',
    method: 'get',
    params: { in_link: link }
  });
}

export function dec_upload(data) {
  return request({
    url: '/app/dec_upload',
    method: 'post',
    data
  });
}

export function _getConfig() {
  return request({
    url: '/index/config',
    method: 'get',
    params: { type: 1 }
  });
}
