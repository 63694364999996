import Vue from 'vue';
import VueRouter from 'vue-router';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '@/store';
// import Index from '@/views/myIndex.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    // 路由懒加载
    component: () => import('../views/myIndex.vue')
  },
  {
    path: '/uplodapp',
    name: 'uplodapp',
    // 路由懒加载
    component: () => import('../views/uploadApp.vue')
  },
  // uploadSignature
  {
    path: '/priceMoney',
    name: 'priceMoney',
    // 路由懒加载
    component: () => import('../views/priceMoney.vue')
  },
  {
    path: '/priceTfsign',
    name: 'priceTfsign',
    component: () => import('../views/priceMoneyview/priceTfsign.vue')
  },
  {
    path: '/priceQiye',
    name: 'priceQiye',
    component: () => import('../views/priceMoneyview/priceQiye.vue')
  },
  {
    path: '/priceMdm',
    name: 'priceMdm',
    component: () => import('../views/priceMoneyview/priceMdm.vue')
  },
  {
    path: '/setMeal',
    name: 'setMeal',
    component: () => import('../views/priceMoneyview/setMeal.vue')
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    // 路由懒加载
    component: () => import('../views/helpCenter.vue')
  },
  {
    path: '/login',
    name: 'login',
    // 路由懒加载
    component: () => import('../views/myLogin.vue')
  },
  {
    path: '/register',
    name: 'register',
    // 路由懒加载
    component: () => import('../views/myRegister.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    // 路由懒加载
    component: () => import('../views/resetPassword.vue')
  },
  {
    path: '/companyProfile',
    name: 'companyProfile',
    // 路由懒加载
    component: () => import('../views/companyProfile.vue')
  },
  {
    path: '/helpIndex',
    name: 'helpIndex',
    redirect: '/downloadPublish1',
    // 路由懒加载
    component: () => import('../views/helpCenterview/helpIndex.vue'),
    children: [
      {
        path: '/downloadPublish1',
        name: 'downloadPublish1',
        component: () => import('../views/helpCenterview/downloadPublish1.vue')
      },
      {
        path: '/downloadPublish2',
        name: 'downloadPublish2',
        component: () => import('../views/helpCenterview/downloadPublish2.vue')
      },
      {
        path: '/downloadPublish3',
        name: 'downloadPublish3',
        component: () => import('../views/helpCenterview/downloadPublish3.vue')
      },
      {
        path: '/downloadPublish4',
        name: 'downloadPublish4',
        component: () => import('../views/helpCenterview/downloadPublish4.vue')
      },
      {
        path: '/downloadPublish5',
        name: 'downloadPublish5',
        component: () => import('../views/helpCenterview/downloadPublish5.vue')
      },
      {
        path: '/downloadPublish6',
        name: 'downloadPublish6',
        component: () => import('../views/helpCenterview/downloadPublish6.vue')
      },
      {
        path: '/signatureEnterprise1',
        name: 'signatureEnterprise1',
        component: () =>
          import('../views/helpCenterview/signatureEnterprise1.vue')
      },
      {
        path: '/signatureEnterprise2',
        name: 'signatureEnterprise2',
        component: () =>
          import('../views/helpCenterview/signatureEnterprise2.vue')
      },
      {
        path: '/signatureEnterprise3',
        name: 'signatureEnterprise3',
        component: () =>
          import('../views/helpCenterview/signatureEnterprise3.vue')
      },
      {
        path: '/TFEnterprise1',
        name: 'TFEnterprise1',
        component: () => import('../views/helpCenterview/TFEnterprise1.vue')
      },
      {
        path: '/exclusiveSignature1',
        name: 'exclusiveSignature1',
        component: () =>
          import('../views/helpCenterview/exclusiveSignature1.vue')
      },
      {
        path: '/exclusiveSignature2',
        name: 'exclusiveSignature2',
        component: () =>
          import('../views/helpCenterview/exclusiveSignature2.vue')
      }
    ]
  },
  {
    path: '/personalCenter/:command',
    name: 'PersonalCenter',
    // redirect:'',
    component: () => import('../views/personalCenter/index.vue')
    // children:[
    //   {
    //     path:''
    //   }
    // ]
  },
  //应用管理-分发应用
  {
    path: '/fenfa',
    name: 'fenfa',
    component: () => import('../views/Application/fenfa.vue')
  },
  {
    path: '/webpackaging',
    name: 'webpackaging',
    component: () => import('../views/Application/webpackaging.vue')
  },
  {
    path: '/download/:id',
    name: 'download',
    component: () => import('../views/Application/download.vue')
  },
  {
    path: '/appDetails/:id/:page',
    name: 'appDetails',
    component: () => import('../views/Application/appDetails.vue'),
    children: [
      {
        path: '/merge',
        name: 'merge',
        component: () => import('../views/Application/Compontent/merge.vue')
      },
      {
        path: '/statistics',
        name: 'statistics',
        component: () =>
          import('../views/Application/Compontent/statistics.vue')
      },
      {
        path: '/versions',
        name: 'versions',
        component: () => import('../views/Application/Compontent/versions.vue')
      },
      {
        path: '/message',
        name: 'message',
        component: () => import('../views/Application/Compontent/message.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 跳转函数
const nexts = (next) => {
  nprogress.start();
  document.documentElement.scrollTop = 0;
  next();
};
// 黑名单
const blackList = ['/uplodapp', '/uploadSignature', '/fenfa', '/webpackaging'];
// 前置路由守卫
router.beforeEach((to, from, next) => {
  if (to.name !== 'download') {
    store.dispatch('ReSetUser');
  }
  // 超时相关
  const nowtime = new Date();
  const time = store.state.time;
  if (nowtime - time > 7200000) {
    store.commit('removeUser');
  }
  // 白名单相关
  const token = store.state.token;
  if (blackList.includes(to.path)) {
    if (token) {
      nexts(next);
    } else {
      if (from.path === '/login') return;
      router.push('/login');
    }
  } else {
    nexts(next);
  }
});

// 后置路由守卫
router.afterEach(() => {
  // 关闭进度条
  nprogress.done();
});

export default router;
