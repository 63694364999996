<template>
  <div id="app">
    <!-- 头部部分 -->
    <header v-if="$route.name !== 'download'">
      <div class="box center">
        <!-- 暂不支持seo优化 -->
        <img src="./assets/logo.png" alt="" width="146px" height="54px" />
        <!-- 导航栏 -->
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/uplodapp">上传应用</el-menu-item>
          <el-menu-item index="/priceMoney">价格</el-menu-item>
          <el-menu-item index="/fenfa">应用分发</el-menu-item>
          <el-menu-item index="/webpackaging">web封装</el-menu-item>
          <el-menu-item index="/helpCenter">帮助中心</el-menu-item>
        </el-menu>
        <!-- 右侧登陆注册按钮 -->
        <!-- 登录状态退出状态管理 -->
        <!-- <div v-if="true"> -->
        <div v-if="!store.state.token">
          <button
            class="btn"
            style="margin-right: 10px; cursor: pointer"
            @click="handleSelect('/login')"
          >
            登录
          </button>
          <button
            class="btn"
            @click="handleSelect('/register')"
            style="cursor: pointer"
          >
            注册
          </button>
        </div>
        <!-- 右侧已登录头像 -->
        <div style="display: flex; align-items: center" v-else>
          <p style="margin-right: 10px">
            {{ $store.state.user.in_username || '' }}
          </p>
          <el-dropdown @command="handleCommand">
            <div class="demo-type">
              <el-avatar :size="40" src="" @error="errorHandler">
                <img
                  src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                />
              </el-avatar>
              <!-- <i class="el-icon-success" style="color: #00fe00;" ></i> -->
              <i class="el-icon-question" v-if="userInfo.in_verify == 0"></i>
              <i
                class="el-icon-success"
                style="color: #00fe00"
                v-if="userInfo.in_verify == 1"
              ></i>
              <i
                class="el-icon-remove"
                style="color: #2fa4e7"
                v-if="userInfo.in_verify == 2"
              ></i>
              <i
                class="el-icon-error"
                style="color: #ff0000"
                v-if="userInfo.in_verify == 3"
              ></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in personalCenter"
                :command="item.command"
                :key="item.name"
              >
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </header>

    <!-- 路由中心部分 -->
    <router-view style="flex: 1" />

    <!-- 底部部分 -->
    <footer v-if="$route.name !== 'download'">
      <div class="center">
        <!-- flex盒子 -->
        <div style="display: flex; border-bottom: 1px solid #8f969a">
          <!-- 占宽度2/3的盒子 -->
          <div style="flex: 2; display: flex; justify-content: space-evenly">
            <!-- 列表1 -->
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span style="font-size: 16px">公司简介</span>
              </div>
              <div
                v-for="(item, index) in list1"
                :key="item"
                class="text item"
                style="cursor: pointer; color: #8f969a"
                @click="handleSelect(`/companyProfile?value=${index + 1}`)"
              >
                {{ item }}
              </div>
            </el-card>
            <!-- 列表2 -->
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span style="font-size: 16px">产品服务</span>
              </div>
              <div
                v-for="item in list2"
                :key="item"
                class="text item"
                style="cursor: pointer; color: #8f969a"
              >
                {{ item }}
              </div>
            </el-card>
            <!-- 列表3 -->
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span style="font-size: 16px">意见反馈</span>
              </div>
              <div
                v-for="item in list3"
                :key="item"
                class="text items"
                style="cursor: pointer; color: #8f969a"
              >
                {{ item }}
              </div>
            </el-card>
          </div>
        </div>

        <!-- 底部描述 -->
        <div style="margin-top: 15px; display: flex; justify-content: center">
          <a href="http://beian.miit.gov.cn" target="_blank">{{
            Config.bei_an
          }}</a>
        </div>
      </div>
    </footer>

    <div class="fixedQQ" @click="qqChatUrl" v-if="$route.name !== 'download'">
      <p style="font-size: 16px; font-weight: 600; padding-bottom: 10px">
        24小时客服
      </p>
      <img src="./assets/1qqqico0.png" alt="" />
      <p style="padding-top: 10px; font-size: 14px">870082323</p>
    </div>
  </div>
</template>

<script scoped>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '@/store';
import { wx_loginApi } from '@/api';
import './css/App.css';
export default {
  data() {
    return {
      scriptElement: null, // 存储外部JavaScript脚本的DOM元素引用
      store: store,
      activeIndex: this.$route.path,
      list1: ['关于我们', '联系我们', '商务合作', '用户协议'],
      list2: ['应用内测分发', '应用在线封装', 'UDID'],
      list3: ['联系我们', '联系QQ：870082323', '客服热线：15515611141'],
      personalCenter: [
        { command: '1', name: '账户设置' },
        { command: '2', name: '实名认证' },
        { command: '3', name: '余额充值' },
        { command: '4', name: '我的订单' },
        { command: '6', name: '个人信息' },
        { command: '50', name: '退出' }
      ]
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user,
      Config: (state) => state.Config
    })
  },
  created() {
    this.setConfig();
    // 引入外部JavaScript脚本
    this.scriptElement = document.createElement('script');
    this.scriptElement.setAttribute('src', '//sdk.51.la/js-sdk-pro.min.js');
    this.scriptElement.setAttribute('charset', 'UTF-8');
    this.scriptElement.setAttribute('id', 'LA_COLLECT');
    document.body.appendChild(this.scriptElement);
    // 当脚本加载完成后初始化LA
    this.scriptElement.onload = () => {
      if (window.LA) {
        LA.init({ id: 'KPLcCKHFTuHcNKir', ck: 'KPLcCKHFTuHcNKir' });
      } else {
        console.error('LA is not loaded properly.');
      }
    };
  },
  methods: {
    ...mapMutations(['setUser', 'removeUser']),
    ...mapActions(['setConfig']),
    qqChatUrl() {
      const qqNumber = '870082323'; // 替换为你想要打开的QQ号码
      window.open(
        `http://wpa.qq.com/msgrd?v=3&uin=${qqNumber}&site=qq&menu=yes`
      );
    },
    handleSelect(key) {
      // 防止重复路由报错
      if (this.activeIndex == key) return;
      this.$router.push(key);
    },
    handleCommand(command) {
      if (command !== '50') {
        const index = this.personalCenter.findIndex(
          (item) => item.command === command
        );
        const params = this.personalCenter[index].command;
        this.handleSelect(`/personalCenter/${params}`);
      } else {
        // 退出函数
        this.$confirm('确认退出？')
          .then((_) => {
            this.removeUser();
            this.handleSelect('/');
          })
          .catch((_) => {});
      }
    },
    errorHandler() {
      return true;
    }
  },
  // 监听路由变化
  watch: {
    $route(to) {
      this.activeIndex = to.fullPath;
      if (to.query.code) {
        wx_loginApi({ code: this.$route.query.code }).then((res) => {
          this.setUser(res.data);
        });
      }
    }
  }
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 使用视窗高度作为最小高度 */
}

.center {
  width: 1200px;
  margin: 0 auto;
}

.fixedQQ {
  position: fixed;
  bottom: 50px;
  right: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.el-menu {
  border-bottom: none !important;
}

.btn {
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

footer {
  width: 100%;
  background-color: #13191c;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.items {
  margin-bottom: 10px;
}

::v-deep .el-card__header {
  border: none;
}

.box-card {
  box-shadow: none;
  width: 200px;
  color: #fff;
  background-color: #13191c;
  border: none;
}
::v-deep .el-card__header {
  padding: 5px 20px;
}
::v-deep .el-card__body {
  padding: 5px 20px;
}
</style>
